export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΡΟΣΟΧΗ: χωρίς τον μοναδικό κωδικό επιστροφής, το δέμα σας δεν μπορεί να αναγνωριστεί."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αριθμός τραπεζικού λογαριασμού ξεκινά με %s (παράδειγμα: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι έχετε συμπεριλάβει τον μοναδικό αριθμό καταγγελίας σας με το προϊόν στη συσκευασία. Ο μοναδικός αριθμός καταγγελίας σας είναι"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχόλιο"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατεστραμμένο προϊόν"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαιρετίσματα"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γειά!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν απαιτούνται πληροφορίες"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει λόγος"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόλις παραληφθεί η επιστροφή σας στα γραφεία μας, θα αποσταλεί ένα e-mail στη διεύθυνση που έχει καταχωρηθεί στη φόρμα καταγγελίας, όπου θα σας ενημερώσουμε για τις τελευταίες ενημερώσεις σχετικά με τη διαδικασία επιστροφής."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός παραγγελίας"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρωμή στην ιστοσελίδα μας, και όχι με την υπηρεσία παράδοσης/courier"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποιότητα προϊόντος"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντικατάσταση"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαδικασία επιστροφής προϊόντος"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαδικασία επιστροφής προϊόντος"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος της καταγγελίας"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρμα επιστροφής χρημάτων"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επιστροφή του προϊόντος είναι γρήγορη και εύκολη"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επιστροφή του προϊόντος είναι γρήγορη και εύκολη"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βήμα"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τύπο καταγγελίας"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλτε το πακέτο στο"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλτε το πακέτο στην ακόλουθη διεύθυνση:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάτι πήγε στραβά, δοκιμάστε ξανά αργότερα ή επικοινωνήστε με την υποστήριξή μας"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβάλετε τη φόρμα"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αριθμός τραπεζικού λογαριασμού ξεκινά με %s (παράδειγμα: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η διαδικασία επιστροφής έχει μόλις προωθηθεί στη διεύθυνση ηλεκτρονικού ταχυδρομείου σας:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μαζί με το προϊόν που θα επιστραφεί, εισάγετε τον μοναδικό κωδικό επιστροφής στη συσκευασία.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είδος πληρωμής"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όταν λάβουμε το πακέτο στο κατάστημά μας, θα ενημερωθείτε για την πορεία της καταγγελίας στην ηλεκτρονική σας διεύθυνση, την οποία δώσατε κατά την υποβολή της φόρμας καταγγελίας"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάθος προϊόν"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάθος ποσότητα"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να εκτυπώσετε ήδη συμπληρωμένες πληροφορίες για να βοηθήσετε στην επιστροφή του προϊόντος"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε ήδη υποβάλει αίτημα επιστροφής. Κάνε υπομονή."])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε λάβει ένα αρχείο PDF για διαδικασία επιστροφής στο συνημμένο."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο μοναδικός αριθμός επιστροφής σας είναι"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πόλη"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αριθμός καταγγελίας"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράδειγμα"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οδηγίες"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επώνυμο"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταχυδρομικός κώδικας"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["δέκτης"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολέας"])}
}